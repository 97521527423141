<template>
  <div class="chat-container">
    <div class="chat-title">
      <div class="chat-name">{{ info.title }}</div>
      <div class="chat-description">{{ info.description }}</div>
    </div>
    <div class="chat-main" ref="scrollView">
      <div class="information-container" v-if="$store.state.messages.length===0">
        <div class="information-list">
          <div class="information-list-title">
            <i class="hmyIconfont hmyIcon-taiyang"></i>
            <div>例子</div>
          </div>
          <div class="information">
            "用简单的术语解释量子计算"
          </div>
          <div class="information">
            "对10岁孩子的生日有什么有创意的想法吗？"
          </div>
          <div class="information">
            "如何用Javascript发出HTTP请求？"
          </div>
        </div>
        <div class="information-list">
          <div class="information-list-title">
            <i class="hmyIconfont hmyIcon-shandian"></i>
            <div>能力</div>
          </div>
          <div class="information">
            记住用户之前在对话中说过的话
          </div>
          <div class="information">
            允许用户提供后续修正
          </div>
          <div class="information">
            受过拒绝不适当请求的训练
          </div>
        </div>
        <div class="information-list">
          <div class="information-list-title">
            <i class="hmyIconfont hmyIcon-jinggao"></i>
            <div>限制</div>
          </div>
          <div class="information">
            可能偶尔会产生不正确的信息
          </div>
          <div class="information">
            可能偶尔会产生有害的指示或有偏见的内容
          </div>
          <div class="information">
            对2021年后的世界和事件了解有限
          </div>
        </div>
      </div>
      <div class="message-list" ref="messageList">
        <div v-for="(message, index) in $store.state.messages" :key="index">
          <div class="time-tips" v-if="message.role!=='system'">{{ renderMessageDate(message, index) }}</div>
          <div class="message-item" v-if="message.role!=='system'">
            <div class="message-item-content" :class="{ self: message.role==='user' }">
              <div class="sender-info">
                <img v-if="message.role==='user'" :src="currentUser.avatar" class="sender-avatar"/>
                <img v-else :src="info.avatar" class="sender-avatar"/>
              </div>
              <div class="message-content">
                <div class="message-payload">
                  <div class="content-text" v-text="message.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">

      <div class="action-bar">
        <!-- 自定义 -->
        <div class="action-item">
          <i class="iconfont icon-liebiao" title="更多" @click="settingBox.visible = true"></i>
        </div>
      </div>

      <div class="input-box">
        <textarea ref="input" @keyup.enter="sendTextMessage" @keyup.shift.enter="nextLine"
                  v-model="text"
                  autocomplete="off" placeholder="请输入（Shift + Enter换行）"
                  class="input-content"></textarea>
      </div>
      <div class="send-box">
        <button class="delete-button" @click="deleteMessages" v-if="$store.state.messages.length!==0">清空会话</button>
        <button class="send-button" @click="sendTextMessage" v-bind:disabled="sendButtonIsDisabled" ref="send-button">{{sendButtonText }}</button>
      </div>
    </div>

    <div class="setting-box" v-if="settingBox.visible">
      <div class="setting-box-main">
        <div class="setting-box-title">设置</div>
        <el-form label-position="top" label-width="80px" :model="settingBox">
          <el-form-item>
            请输入系统提示词:
            <el-input v-model="$store.state.AI_settingBox.text"></el-input>
          </el-form-item>
          <div class="setting-box-footer">
            <el-button size="medium" @click="settingBox.visible = false" plain>关闭</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>


</template>

<script>
  import {formatDate} from '../utils/utils.js'

  export default {
    name: 'ChatGPT',
    components: {},
    data() {
      return {
        currentUser: null,
        info: {
          title: 'AI问答',
          avatar: '/static/images/ChatGPT.png',
          description: '基于 gpt-3.5-turbo 的自然语言模型人工智能对话'
        },

        text: '',

        sendButtonIsDisabled: false,
        sendButtonText: "发送",

        settingBox: {
          visible: false,
        },
      };
    },
    created() {
      this.currentUser = this.globalData.currentUser;
      let messages= JSON.parse(localStorage.getItem("messages_"+this.currentUser.id));
      if (messages){
        this.$store.state.messages=messages;
      }
      this.scrollToBottom();
    },
    computed: {

    },
    methods: {
      formatDate,

      sendTextMessage(event) {
        if (event.shiftKey){
          return;
        }
        if (this.$store.state.isTalking===true){
          return;
        }
        if (this.text.trim().length === 0) {
          console.log('输入不能为空');
          return;
        }
        let message = {
          role: 'user',
          content: this.text,
          timestamp: Date.now()
        };
        this.text = '';
        this.$store.state.messages.push(message);
        localStorage.setItem("messages_"+this.currentUser.id, JSON.stringify(this.$store.state.messages));
        // this.$store.dispatch('getMessage');

        this.$store.dispatch('getMessageByStream',this.$refs);

      },

      scrollToBottom() {
        this.$nextTick(() => {
          this.$refs.scrollView.scrollTop = this.$refs.messageList.scrollHeight;
        });
      },
      renderMessageDate(message, index) {
        if (index === 0) {
          return this.formatDate(message.timestamp);
        } else {
          if (message.timestamp - this.$store.state.messages[index - 1].timestamp > 5 * 60 * 1000) {
            return this.formatDate(message.timestamp);
          }
        }
        return '';
      },
      deleteMessages(){
        this.$confirm('确定要清空当前会话吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.state.messages=[];
          localStorage.removeItem("messages_"+this.currentUser.id);
        })
      },
      nextLine(){
        this.text+='\n';
      },
    },
    watch:{
      "$store.state.isTalking": function(newValue, oldValue) {
        if (newValue===false){
          this.sendButtonIsDisabled=false;
          this.sendButtonText = "发送";
          this.$refs["send-button"].style.cursor = 'pointer';
        }else {
          this.sendButtonIsDisabled=true;
          this.sendButtonText="响应中";
          this.$refs["send-button"].style.cursor='not-allowed';
        }
      },
      "$store.state.messages": function(newValue, oldValue) {
       this.scrollToBottom();
      },
    }
  };
</script>

<style scoped>
  .chat-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .chat-title {
    height: 40px;
    padding: 5px 15px 0 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid #dcdfe6;
  }

  .chat-name {
    width: 80px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    color: #333333;
  }

  .chat-description {
    width: 350px;
    font-size: 13px;
    color: #606060;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-main {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    scrollbar-width: thin;
  }

  .chat-main::-webkit-scrollbar {
    width: 0;
  }

  .time-tips {
    color: #999;
    text-align: center;
    font-size: 12px;
  }

  .message-list {
    padding: 0 10px;
  }

  .message-item {
    display: flex;
  }

  .message-item-checkbox input[type="checkbox"]::before, .message-item-checkbox input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    background: #FFFFFF;
    width: 18px;
    height: 18px;
    border: 1px solid #cccccc;
    border-radius: 50%;
  }

  .message-item-checkbox input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #1E88E5;
    width: 18px;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
  }

  .message-item-content {
    flex: 1;
    margin: 5px 0;
    overflow: hidden;
    display: flex;
  }

  .sender-info {
    margin: 0 5px;
  }

  .sender-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .message-content {
    max-width: calc(100% - 100px);
  }

  .message-payload {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .content-text {
    display: flex;
    align-items: center;
    text-align: left;
    background: #f2f2f2;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
    margin: 3px 0;
    line-height: 25px;
    white-space: pre-line;
    overflow-wrap: anywhere;
    border-radius: 8px;
    word-break: break-all;
  }

  .message-item .self {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  .message-item .self .content-text{
    background: #58a8ff;
    color: #FFFFFF;
  }

  .chat-footer {
    border-top: 1px solid #dcdfe6;
    width: 100%;
    height: auto;
    background: #FFFFFF;
  }

  .action-bar {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
  }

  .action-bar .action-item {
    text-align: left;
    padding: 10px 0;
    position: relative;
  }

  .action-bar .action-item .iconfont {
    font-size: 22px;
    margin: 0 10px;
    z-index: 3;
    color: #606266;
    cursor: pointer;
  }

  .action-bar .action-item .iconfont:focus {
    outline: none;
  }

  .action-bar .action-item .iconfont:hover {
    color: #1E88E5;
  }

  .input-box {
    padding: 0 10px;
    flex: 1;
  }

  .input-content {
    border: none;
    resize: none;
    display: block;
    padding: 5px 15px;
    box-sizing: border-box;
    width: 100%;
    color: #606266;
    outline: none;
    background: #FFFFFF;
    word-break: break-all;
  }

  .send-box {
    /*margin-top: 15px;*/
    padding: 5px 10px;
    text-align: right;
  }

  .send-button {
    width: 70px;
    height: 30px;
    font-size: 15px;
    border: 1px solid #1E88E5;
    background-color: #ffffff;
    color: #1E88E5;
    border-radius: 5px;
    cursor: pointer;
  }

  .send-button:hover{
    background-color: #1E88E5;
    color: #ffffff;
  }

  .delete-button {
    width: 90px;
    height: 30px;
    font-size: 15px;
    border: 1px solid #F56C6C;
    background-color: #ffffff;
    color: #F56C6C;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }

  .delete-button:hover {
    background-color: #F56C6C;
    color: #ffffff;
  }

  .action-popup-main .action-item {
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    color: #262628;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
  }

  .information-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0px 30px 0px 30px;
    box-sizing: border-box;
    justify-content: center;
    /*align-content: flex-start;*/
    align-items: center;
  }

  .information-list{
    width: 30%;
  }

  .information-list-title{
    font-size: 18px;
    text-align: center;
  }

  .information-list-title i{
    font-size: 30px;
  }

  .information{
    background: #f5f5f5;
    margin: 5px;
    font-size: 14px;
    color: #333333;
    border-radius: 10px;
    padding: 15px 20px;
  }

  .information:hover{
    background: #d5d5d5;
  }

  .setting-box {
    width: calc(100% + 61px);
    height: 100%;
    position: absolute;
    top: 0;
    left: -61px;
    background: rgba(51, 51, 51, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .setting-box-main{
    width: 300px;
    /*height: 200px;*/
    background: #ffffff;
    z-index: 100;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
  }

  .setting-box-title{
    font-size: 25px;
    margin-bottom: 20px;
  }

  .setting-box-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
  }

  @media screen and (max-width: 550px){
    .chat-description{
      display: none;
    }
  }

  @media screen and (max-width: 700px){
    .setting-box{
      width: 100%;
      left: 0;
    }
  }



</style>
